:root {
  --black: #000;
  --white: #fff;
  --blue: #272846;
  --red: #ff0000;
  --lightestBlue: #68697e;
  --lighterBlue: #81829a;
  --lighterGray: #e5e5e5;
  --lightestGray: #e8e8e8;
  --lightGray: #8a8a8a;
  --mediumGray: #6f6f6f;
  --gray: #4c4c4c;
  --orange: #f18e4a;
  --negro: #4c4c4c;
  --lightNegro: #9091a6;
  --lighterNegro: #b6b7c1;
  --mediumNegro: #b3b3b3;
}

html {
  background-color: var(--lighterGray);
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--lighterGray) !important;
  font-family: "Roboto", sans-serif;
}

* {
  margin: 0;
  box-sizing: border-box;
}
